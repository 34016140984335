import $ from 'jquery';

const customiseModule = (props) => {

    var state = {
		hidden: 'is-hidden',
		visible: 'is-visible',
		selected: 'is-selected',
		active: 'is-active',
		inactive: 'is-inactive',
		removed: 'is-removed',
		processing: 'is-processing',
		loading: 'is-loading'
	}

    // Tabs

    var tabs = {
        load: function(url, container) {

            $.ajax(
                {
                    url: url
                }
            )
            .done(
                function(data) {

                    // Load Data
                    $('.js-tab-container[data-tab-container=' + container +  ']')
                        .removeClass(state.loading)
                        .html(data);

                    // tooltip.init();
                    customise.init();
                    // customScrollbar.init();

                }
            );

        },
        init: function() {

            if ($('.js-tab-load').length) {

                $('.js-tab-load')
                    .each(
                        function(){

                            // Get Container
                            var container = $(this).data('tab-container');

                            // Load
                            tabs.load(
                                $('.js-tab-load[data-tab-container=' + container + ']').data('tab-url'),
                                container
                            );

                        }
                    );
            }

            $('.js-tab-link')
                .click(
                    function(event){

                        // Get Container
                        var container = $(this).data('tab-container');

                        // Update Tab Links
                        $('.js-tab-link').removeClass(state.active);
                        $(this).addClass(state.active);

                        // Empty Container
                        $('.js-tab-container[data-tab-container=' + container +  ']')
                            .empty()
                            .addClass(state.loading);

                        // Load
                        tabs.load(
                            $(this).data('tab-url'),
                            container
                        );

                        event.preventDefault();
                    }
                );

        }
    }

// Customise

    var customise = {
        loadRender: function(object) {

            

            console.log(object);

            var render = object.data('customise-render');
            var renderSplit = render.split(',');

            var renderLayer = object.data('customise-render-layer');
            var renderValue = object.data('customise-render-value');

            var renderLayerInput = $('input[data-customise-render-layer-id="' + renderLayer + '"]');

            // Display Loader
            $('.js-customise-render-container')
                .addClass(state.loading)
                .delay(750)
                .queue(
                    function(next){

                        $.each(
                            renderSplit,
                            function(index, value) {

                                $('.js-customise-render-view:eq(' + index + ') [data-customise-render-layer-id=' + renderLayer + ']')
                                    .css('background-image', 'url(' + value + ')');

                                    console.log(value);

                                if ($('[data-customise-type]').data('customise-type') === 'related' && renderLayer == 'layer1')
                                {
                                    $('.js-customise-render-view:eq(' + index + ') [data-customise-render-layer-id="layer2"]')
                                        .css('background-image', '');
                                }

                            }
                        );

                        // $('.js-customise-render-container')
                        //     .imagesLoaded(
                        //         {
                        //             background: true
                        //         },
                        //         function() {

                        //             // Remove loader when loaded in to container
                        //             $('.js-customise-render-container')
                        //                 .removeClass(state.loading);

                        //         }
                        // );

                        $('.js-customise-render-container')
                            .removeClass(state.loading);

                        next();

                    }
                )
                ;

            // Remove class off old option and add to selected option
            $('[data-customise-render-layer=' + renderLayer + ']').removeClass(state.active);
            object.addClass(state.active);

            // Save option
            renderLayerInput.val(renderValue);


        },
        form: function() {

            $('body')
                .on(
                    'click',
                    '.js-customise-form-trigger',
                    function(event){

                        document.querySelector('body').scrollIntoView({ behavior: 'smooth' })

                        if ($('.js-customise-form').hasClass(state.active)) {
                            $('.js-tab-container').removeClass('hidden');
                            $('.js-customise-form-open').removeClass('hidden');
                            $('.js-customise-form').removeClass(state.active);
                        }
                        else {
                            $('.js-tab-container').addClass('hidden');
                            $('.js-customise-form-open').addClass('hidden');
                            $('.js-customise-form').addClass(state.active);
                        }

                        event.preventDefault();
                    }
            );

        },
        init: function() {

            $('.js-customise-render-nav')
                .click(
                    function(event) {

                        var index = $(this).data('customise-render-index');

                        $('.js-customise-render-view')
                            .removeClass(state.active)

                        $('.js-customise-render-view:eq(' + index + ')')
                            .addClass(state.active);

                        $('.js-customise-render-nav').parent().removeClass(state.active);
                        $(this).parent().addClass(state.active);

                        event.preventDefault();
                    }
                );

            // If customise type is related, then load the related swatches related to Layer 1
            if ($('[data-customise-type]').data('customise-type') === 'related') {

                $('.js-customise-item')
                    .each(
                        function(event){

                            if (
                                typeof $(this).data('customise-related-swatch') !== 'undefined' &&
                                $(this).data('customise-render-layer') == 'layer2' &&
                                $('input[data-customise-render-layer-id="layer1"]').val() !== $(this).data('customise-related-swatch')
                            )
                            {

                                $(this)
                                    .removeClass(state.active)
                                    .hide();

                            }

                        }
                    );

                $('.js-customise-item:visible').eq(0).addClass(state.active);

            }

            // Load selected value after AJAX load
            $('input[data-customise-render-layer-id]')
                .each(
                    function() {

                        var renderLayer = $(this).data('customise-render-layer-id');
                        var renderValue = $(this).val();

                        if (renderValue === undefined || renderValue === '') {
                            var renderValue = $('.js-customise-item.is-active[data-customise-render-layer="' + renderLayer + '"]').data('customise-render-value');
                            $(this).val(renderValue);
                        }

                        $('[data-customise-render-layer=' + renderLayer + ']').removeClass(state.active);

                        $('.js-customise-item[data-customise-render-value="' + renderValue +  '"]').addClass(state.active);

                    }
                );

            $('.js-customise-item')
                .click(
                    function(event){

                        console.log('Clicked');

                        customise.loadRender($(this));

                        event.preventDefault();
                    }
                );

            // $('.js-customise-render-container')
            //     .imagesLoaded(
            //         {
            //             background: true
            //         },
            //         function() {
            //             $('.js-customise-render-container')
            //                 .removeClass(state.loading);
            //         }
            // );

            $('.js-customise-render-container')
                .removeClass(state.loading);

        }
    }

    customise.init()
    customise.form()
    tabs.init()

}

export { customiseModule };
