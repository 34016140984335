/* CSS */
import "@css/app.scss";

/* JS */
import Alpine from 'alpinejs';
import Cookies from 'js-cookie';
import Masonry from 'masonry-layout';
import AOS from 'aos';
import Splitting from "splitting";
import Rellax from 'rellax';
import LocomotiveScroll from 'locomotive-scroll';
import axios from 'axios';
import fileDownload from 'js-file-download';
import Flickity from "flickity-fade";
import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'

document.documentElement.classList.remove('no-js');

// Modules

import { customiseModule } from './modules/customise';
import { imageSecurity } from './modules/imageSecurity';

// Exports

customiseModule();
imageSecurity();

// Global Exports

window.Cookies = Cookies
window.Flickity = Flickity
window.Masonry = Masonry
window.AOS = AOS
window.axios = axios
window.fileDownload = fileDownload
window.Alpine = Alpine
window.lightGallery = lightGallery
window.lgThumbnail = lgThumbnail
window.lgZoom = lgZoom

if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) == false){
    window.Splitting = Splitting
    window.Rellax = Rellax
    window.LocomotiveScroll = LocomotiveScroll
}

Alpine.start()